import * as React from "react";
import { navigate } from "@reach/router";
import { useAsync } from "react-async-hook";

function NotFoundPage() {
  useAsync(async () => {
    await navigate("/");
  }, []);
  return null;
}

export default NotFoundPage;
